<template>
  <div class="extraFields">

    <!-- email -->
    <b-field :label="`${$t('profile.email')} ${emailMandatory ? '*':''}`" class="mb-0" :type="{'is-danger': emailIsWarned}" :message="emailWarnMessage">
      <b-input v-model="values.email" type="email" maxlength="30" expanded :required="emailMandatory"></b-input>
    </b-field>

    <!-- Extra fields (setup in backend per project) -->
    <b-field v-for="(field, i) in extraFields" :key="i" :label="GetFieldLabel(field)">
      <!-- text field -->
      <template v-if="field.type == 'text'">
        <b-input v-model="values[field.name]" type="text" :required="field.required"></b-input>
      </template>
      <!-- select field -->
      <template v-else-if="field.type == 'select'">
        <b-select :placeholder="$t('profile.chooseOne')" expanded v-model="values[field.name]" :required="field.required">
          <option v-for="option in field.options" :value="option.value || option" :key="option.value || option">
            {{ option.label || option }}
          </option>
        </b-select>
      </template>
      
      <template v-else>
        {{$t('profile.fieldTypeNotSupported',[field.type])}}
      </template>
    </b-field>

    <!-- {{extraFields}} -->

    <!-- Accept terms -->
    <b-field class="mb-0" v-if="$store.state.setup.registration.termsRequired">
      <b-checkbox
        v-model="values.acceptTerms"
        required
        size="is-small"
        :disabled="disableAcceptTerms">
        {{ $t('profile.acceptTermsField_outsideLink') }}
        <a href="/terms" target="_blank"> {{ $t('profile.acceptTermsField_insideLink') }}</a>
      </b-checkbox>
    </b-field>

  </div>
</template>

<script>
import axios from "axios";
const whitelabelConfig = require("@/whitelabel.config");

export default {
  data() {
    return {
      emailIsWarned: false,
      emailWarnMessage: "",
      allowedDomains: this.$store.state.setup.registration.allowedDomains?.replaceAll(" ", "").split(","),
      allowedMails: this.$store.state.setup.registration.allowedMails?.replaceAll(" ", "").split(","),
      values: {
        email: this.GetInitialEmail(),
        acceptTerms: this.$store.state.user.profile.acceptTerms || false,
      },
      disableAcceptTerms: this.$store.state.user.profile.acceptTerms
    }
  },
  computed: {
    extraFields() {
      return this.$store.state.user.extraFields // ... ?
    },
    emailMandatory(){
      return this.$store.state.setup.registration.emailMandatory
    }
  },
  watch: {
    "values.email": function() {
      if(this.allowedMails.includes(this.values.email)){ 
        // Si ya esta incluido en los permitidos nos salteamos todo
        this.emailIsWarned = false
        this.emailWarnMessage = ""
        return
      } 
      if(this.allowedDomains == "" || this.allowedDomains == undefined){ return }
      const userMailArr = this.values.email.split("@")
      if(userMailArr.length <= 1){ return } // Si ya puso @
        if(!userMailArr[1].includes(".")){ return } // Si en el dominio ya puso .
        const userMailDomain = userMailArr[1]
        if(!this.allowedDomains.includes(userMailDomain)){
          // Si el dominio está incluido
          this.emailIsWarned = true
          this.emailWarnMessage = "Correo no permitido" //pacha agregar traduccion Domain not allowed
        }else{
          this.emailIsWarned = false
          this.emailWarnMessage = ""
        }
    }
  },
  methods: {
    RetrieveFields(){
      var fields = this.values
      // if(fields.email === null){
      //   fields.email == ""
      // }
      if(!fields.email){
        fields.email = ""
      }
      // console.log("***",fields)
      return fields;
    },  
    GetFieldLabel(field){
      let label = ""
      if(!field.label){
        label = this.$t("profile." + field.name); // primero lo intento traducir. si no existe me devuelve el mismo string
        label = this.$camelToWords(label);
      }else{
        if(typeof(field.label) == "object"){
          const lang = whitelabelConfig.defaultLanguage || 'en'
          label = field.label[lang]
        }else{
          label = field.label
        }
      }
      if(field.required){
        label = label + " *"
      }
      return label
    },
    GetInitialEmail() {
      return this.$store.state.user.profile.confirmed
        ? this.$store.state.user.profile.email
        : null;
    },
    LoadFieldsFromStore() {
      this.extraFields.forEach((field) => {
        this.$set(this.values, field.name, this.$store.state.user.profile[field.name] || null)
      })
    },
    validate() {
      // Validate email. Solo si no esta incluido en los allowed
      if(!this.allowedMails?.includes(this.values.email)){
        if(this.allowedDomains != "" && this.allowedDomains != undefined){
          const userMailDomain = this.values.email.split("@")[1]
          if(!this.allowedDomains.includes(userMailDomain)){
            return false
          }
        }
      }

      // Accept terms
      if (this.$store.state.setup.registration.termsRequired && !this.values.acceptTerms) {
        this.$buefy.notification.open({
          message: this.$t('profile.haveToAcceptTerms'),
          type: 'is-warning' });
        this.$sfxPlay('negativenotification')
        return false;
      }

      // required fields
      let invalidFields = 0
      this.extraFields.filter(x => x.required).forEach(field => {
        if(this.values[field.name] == null){
          invalidFields ++
        }
      })
      if(invalidFields != 0){
        this.$buefy.notification.open({
            message: this.$t('profile.requiredFieldsAreEmpty'),
            type: 'is-warning'
        });
        this.$sfxPlay('negativenotification')
        return false
      }
      return true
    }
  },
  async mounted() {
    if (!this.extraFields.length) {
      this.$store.commit("StartLoading")
      const extraFields = await axios.get(process.env.VUE_APP_API_BASE + `/api/globals/setup/extrafields`)
      this.$store.commit("user/SetUserExtraFields", extraFields.data)
      this.LoadFieldsFromStore()
      this.$store.commit("StopLoading")
    }
  }

}
</script>
